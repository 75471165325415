import React from "react";
import AdminParticipantFormCancel from "../admin/ticketing/forms/AdminParticipantFormCancel";

class RegistrationCustomButton extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
      }
  }
  
  render() {
    const {page, regPages, question, user_email, formikProps} = this.props;
    let renderedButton = <></>;
    switch(question.properties.custom_function_id) {
      case "contact-us":
        renderedButton = (
          <div className={`col-xs-12 col-md-3 ${question.custom_class}`}>
            <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%20BottleRock%202024" target="_blank" style={{textDecoration: "none"}}>
              <div className="sg-button sg-submit-button reg-button" style={{textAlign: "center"}}>
                {question.label}
              </div>
            </a>
          </div>
        );
        break;
      case "cancel-registration":
        renderedButton = (
          <AdminParticipantFormCancel question={question} formikProps={formikProps} user_email={user_email} />
        )
        break;
      default:
        renderedButton = <></>;
    }
    return renderedButton;
  }
}

export default RegistrationCustomButton;
