import React from "react";
import PropTypes from "prop-types";
import { fetchAPI } from "@utility/NetworkUtils"
class RegistrationDecline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      emailValue: "",
      success: false,
      date: null,
    }
    this.declineEmail = this.declineEmail.bind(this);
  }


  declineEmail(e) {
    e.preventDefault()
    const { emailValue } = this.state;
    fetchAPI(`/registration/decline`,(json)=>{
      console.log(json);
      if(json && json.error == null){
        this.setState({
          success: true,
          date: json.date,
        });
      }else if(json && json.error) {
        this.setState({
          error: json.error
        })
      }else {
        this.setState({
          error: "An error occurred. Please try again in a moment."
        })
      }
    },{
      method: 'POST',
      body: {
        email: emailValue||""
      }
    })
  }


  render() {
    const { emailValue, error, success, date} = this.state;
    if(success) {
      return (
        <div className={`registration-page-one container`} style={{maxWidth: "630px", padding: "0px"}}>
          <div className="att-proam-registration-email-validation registration-form">
            <div className="row">
              <div className="col-xs-12 att-proam-registration-email-validation-copy" style={{textAlign: "left"}}>
                Thank you for confirming your response.
              </div>
              <div className="col-xs-12 att-proam-registration-email-validation-copy" style={{textAlign: "left"}}>
                Sorry we’ll miss you at the BottleRock 2024.
              </div>
              <div className="col-xs-12 att-proam-registration-email-validation-copy" style={{textAlign: "left", fontSize: "14px", paddingTop: "15px"}}>
                If you have any questions, please contact the Cisco Executive Events Team.
              </div>
            </div>
            <div className="att-proam-registration-email-validation-submit" style={{textAlign: "left"}}>
              <a target="_blank" href="mailto:executiverelations@cisco.com?subject=Question%20%7C%20BottleRock%202024">
                <button type="button" style={{paddingTop: "10px", width: "auto", marginTop: "20px"}} className="sg-button sg-submit-button">
                  Contact Us
                </button>
              </a>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className={`registration-page-one container`} style={{maxWidth: "630px", padding: "0px"}}>
        <form src="#" onSubmit={this.declineEmail} className="att-proam-registration-email-validation registration-form ">
          <div className="row" >
            <div className="col-xs-12 att-proam-registration-email-validation-copy" style={{textAlign: "center", paddingBottom: "10px"}}>
              Thank you for your response.
            </div>
            <div className="col-xs-12 att-proam-registration-email-validation-copy" style={{textAlign: "center", fontSize: "15px", lineHeight: "20px"}}>
              Sorry we’ll miss you at the BottleRock 2024.
              <br />
              We look forward to hosting you at a future Cisco event.
              <br/>
            </div>
          </div>
          <div className="att-proam-registration-email-validation-fields" style={{padding: "0px 30px"}}>
            <br/>
            <div className="label-wrapper" style={{textAlign: "center"}}>
              <label className="label-english" style={{fontSize: "14px"}}>To confirm your response, please enter your email address below.</label>
            </div>
            <input
              value={emailValue}
              onChange={(e) => this.setState({
                emailValue: e.target.value
              })}
              className="form-control"
            />
          </div>
          {error ? (
            <div className="att-proam-registration-email-validation-error error-red">
              {error}
            </div>
          ):""}
          <div className="att-proam-registration-email-validation-submit" style={{textAlign: "center"}}>
            <br/>
            <button
              type="button"
              onClick={this.declineEmail}
              className="sg-button sg-submit-button"
              style={{width: "auto"}}
            >
              Submit
            </button>
          </div>
          <div style={{paddingTop: "30px"}}>
            <div className="row" >
              <div className="col-xs-12 att-proam-registration-email-validation-copy" style={{textAlign: "center", fontSize: "14px"}}>
                <br/>
                If you have any questions, please <a href="mailto:executiverelations@cisco.com?subject=Question%20%7C%20BottleRock%202024">contact</a> the Cisco Executive Events Team.
                <br/>
                <br/>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default RegistrationDecline;
