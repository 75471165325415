import React from "react";
import LandingFaqItem from "./LandingFaqItem";

class LandingFaq extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
      }
  }
  
  faqItems() {
    return [
      {
        question: "When will I receive my tickets?",
        answer: "<p>Upon your arrival at your selected program check-in location, please visit either the Kendall Room at The Carolina Hotel (Pinehurst, NC), or the Salon at The Umstead Hotel (Cary, NC), where you will receive your mobile ticket(s) and course essentials.</p>"
      },
      {
        question: "What is included with my registration?",
        answer: "<p>As our guest, you will enjoy full-day championship access, complimentary food and beverage in our Cisco Suite in the Village on 18 and dedicated round trip shuttle service to Pinehurst&nbspNo.&nbsp;2. Guests are responsible for their own transportation to and from our program hotels.</p>"
      },
      {
        question: "How do I change the program check-in location I selected during registration?",
        answer: "<p>If you would like to update your program check-in location, or have questions regarding your selection, please contact <a href='mailto:executiverelations@cisco.com?subject=Question%20%7C%20BottleRock%202024'>executiverelations@cisco.com</a> by Friday, June 7, 2024. All guests must be 21 years of age or older.</p>"
      },
      {
        question: "How do I change/add a guest?",
        answer: "<p>If your guest changes or you’d like to add a guest, please contact <a href='mailto:executiverelations@cisco.com?subject=Question%20%7C%20BottleRock%202024'>executiverelations@cisco.com</a> by Tuesday, June 11, 2024. All guests must be 21 years of age or older.</p>"
      },
      {
        question: "Is parking available at Pinehurst Resort & Country Club (No. 2) for the championship?",
        answer: "<p>Parking at Pinehurst Resort & Country Club is extremely limited during the championship. If you are interested in parking at Pinehurst Resort & Country Club (No. 2), or if you need special accommodations, please contact us directly at <a href='mailto:executiverelations@cisco.com?subject=Question%20%7C%20BottleRock%202024'>executiverelations@cisco.com</a>.</p>"
      },
      {
        question: "What should I wear?",
        answer: "<p>Suggested attire for the championship is golf casual; comfortable footwear is highly encouraged. It is also recommended to keep an eye on local forecasts and come prepared with rain gear and/or sweater/jacket options in the event of inclement weather.</p>"
      },
      {
        question: "I forgot to add dietary restrictions during registration, who do I contact to make updates?",
        answer: "<p>Please contact <a href='mailto:executiverelations@cisco.com?subject=Question%20%7C%20BottleRock%202024'>executiverelations@cisco.com</a> for questions and assistance.</p>"
      }
    ];
  }

  render() {
    const { cms_section_faqs } = this.props;
    let renderedQuestions = cms_section_faqs["items"].sort((a,b) => a.answers.sort_index - b.answers.sort_index).map((item, index) =>
      <LandingFaqItem item={item} key={`faq-item-${index}`}/>
    )
    return renderedQuestions;

    // let renderedQuestions = this.faqItems().map((item, index) =>
    //   <LandingFaqItem item={item} key={`faq-item-${index}`}/>
    // )
    // return renderedQuestions;
  }
}

export default LandingFaq;
