import React from "react";

class LandingPageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  toggleOpen() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }
  renderDropdownIcon() {
    const { isOpen } = this.state
    return (
      <img onClick={this.toggleOpen} src={`/images/landing/${isOpen ? "menuclose.png" : "menuopen.png"}`} className={`sg-landing-page-mobile-menu-dropdown-icon ${isOpen ? "open" : "closed"}`} alt=""/>
    )
  }

  renderDesktopHeader() {
    return (
      <nav class="navbar navbar-inverse header-desktop" role="navigation">
        <div class="sg-background-container" style={{height: "266px"}}>
        </div>
      </nav>
    )
  }

  renderTabletHeader() {
    return (
      <nav class="navbar navbar-inverse header-tablet" role="navigation" style={{marginTop: "0px"}}>
        <div className="" style={{position: "relative"}}>
          <img src="/images/landing/header-tablet.png" alt=""/>
        </div>
      </nav>
    );
  }

  renderMobileHeader() {
    return (
      <nav class="navbar navbar-inverse header-mobile-landing" role="navigation" style={{marginTop: "0px"}}>
        <div className="" style={{position: "relative"}}>
         
          <img src="/images/landing/header-mobile-img.png" alt=""/>

        </div>
      </nav>
    )
  }

  renderMenu() {
    const { isOpen } = this.state
    return (
      <div className="landing-page-header-mobile-menu">
        {isOpen ? (
          this.renderMenuInner()
        ):""}
      </div>
    )
  }

  renderMenuInner() {
    return (
      <div class="landing-page-header-nav-wrapper">
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="home_nav">
            <a href="#home">
            Home
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="itinerary_nav">
            <a href="#itinerary">
            Itinerary
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="private_nav">
            <a href="#private">
            Private Event
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="festival_nav">
            <a href="#festival">
            Festival Map
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="platinum_nav">
            <a href="#platinum">
            Platinum Experience
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="suite_nav">
            <a href="#suite">
            Cisco Suite
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="shuttle_nav">
            <a href="#shuttle">
            Shuttle Service
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="connected_nav">
            <a href="#connected">
            Get Connected
            </a>
        </div>
        <div class="landing-page-header-nav-item" onClick={this.toggleOpen} id="contact_nav">
            <a href="#contact">
            Contact Us
            </a>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>

        <div className="navbar-mobile-landing-page-addition">
          {/* <img src="/images/landing/Cisco_Logo.svg" className="navbar-mobile-landing-page-addition-cisco-logo" alt=""/> */}
          {this.renderDropdownIcon()}
          {this.renderMenu()}
        </div>
        {this.renderDesktopHeader()}
        {this.renderTabletHeader()}
        {this.renderMobileHeader()}

      </>
    )

  }
}

export default LandingPageHeader;
